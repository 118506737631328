<template>
  <prime-sidebar
    ref="sidebar"
    :position="position"
    :block-scroll="true"
    :modal="modal"
    :pt="{
      root: {
        class: ['de-sidebar', `de-sidebar-${props.position}`],
      },
      header: { class: ['de-sidebar-header', props.headerClass] },
      content: { class: ['de-sidebar-content', props.contentClass] },
      closeButton: { class: 'de-sidebar-close' },
      mask: { class: ['de-sidebar-mask', { 'is-visible': props.modal }] },
      transition: {
        ...transitionClasses,
      },
    }"
  >
    <template #header>
      <slot name="header" />
    </template>

    <template #default>
      <slot></slot>

      <div v-if="$slots.footer" class="de-sidebar-footer">
        <slot name="footer" />
      </div>
    </template>
  </prime-sidebar>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import type { PrimeSidebar } from '#components';

const props = withDefaults(
  defineProps<{
    position?: 'left' | 'top' | 'bottom' | 'right' | 'full';
    headerClass?: string;
    contentClass?: string;
    modal?: boolean;
  }>(),
  {
    position: 'left',
  },
);

const transitionClasses = computed(() => {
  return props.position === 'top'
    ? {
        enterFromClass: 'tw-translate-x-0 tw--translate-y-full tw-translate-z-0',
        leaveToClass: 'tw-translate-x-0 tw--translate-y-full tw-translate-z-0',
      }
    : props.position === 'bottom'
      ? {
          enterFromClass: 'tw-translate-x-0 tw-translate-y-full tw-translate-z-0',
          leaveToClass: 'tw-translate-x-0 tw-translate-y-full tw-translate-z-0',
        }
      : props.position === 'left'
        ? {
            enterFromClass: 'tw--translate-x-full tw-translate-y-0 tw-translate-z-0',
            leaveToClass: 'tw--translate-x-full tw-translate-y-0 tw-translate-z-0',
          }
        : props.position === 'right'
          ? {
              enterFromClass: 'tw-translate-x-full tw-translate-y-0 tw-translate-z-0',
              leaveToClass: 'tw-translate-x-full tw-translate-y-0 tw-translate-z-0',
            }
          : {
              enterFromClass: 'tw-opacity-0',
              enterActiveClass: 'tw-transition-opacity tw-duration-400 tw-ease-in',
              leaveActiveClass: 'tw-transition-opacity tw-duration-400 tw-ease-in',
              leaveToClass: 'tw-opacity-0',
            };
});

const sidebar = ref<InstanceType<typeof PrimeSidebar> | null>(null);

function close() {
  if (!sidebar.value) return;

  (sidebar.value as InstanceType<typeof PrimeSidebar>).hide();
}

defineExpose({
  close,
});
</script>
